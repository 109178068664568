import { useState, useEffect } from 'react'
import {
	Button,
	CircularProgress,
	Grid,
	Paper,
	Typography
} from '@mui/material'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import footerImage from 'assets/Footer-Image.png'
import payoffOneImage from 'assets/login_image.png'
import EmailPopup from '../EmailPopup'
import { earlyRegistration } from 'api/preMktg'
import { getActionIdFromURL } from '../../utils/common.util'

const NavbarHPCT = () => {
	const { t } = useTranslation()

	const [email, setEmail] = useState('')
	const [showModal, setShowModal] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [action_id, setActionId] = useState(null) // Declare a new state variable

	// Call the utility function inside a useEffect so it runs when the component mounts
	useEffect(() => {
		const actionIdFromURL = getActionIdFromURL() // Get action_id from URL
		setActionId(actionIdFromURL) // Set action_id to state
	}, [])

	const handleEarlyReg = async () => {
		setIsLoading(true)
		try {
			const payload = { email }

			if (action_id != null) {
				// Checks for both `null` and `undefined`
				payload.action_id = action_id
			}

			await earlyRegistration(payload)
			setIsLoading(false)
			setShowModal(true)
		} catch (error) {
			setEmail('')
			setIsLoading(false)

			if (error.response) {
				toast(error.response.data.message)
			} else if (error.request) {
				toast(error.request)
			} else {
				toast(error.message)
			}
		}
	}

	return (
		<Grid container className="header" direction="row-reverse">
			<Grid
				item
				xs={0}
				sm={0}
				md={5.6}
				align={'right'}
				display={{ xs: 'none', sm: 'none', md: 'block' }}
			>
				<img
					src={payoffOneImage}
					alt="Improve your study"
					className="payoff-one-image"
				/>
			</Grid>

			<Grid
				item
				xs={1}
				sm={1}
				md={0}
				align={'right'}
				display={{ xs: 'block', sm: 'block', md: 'none' }}
			>
				<Paper />
			</Grid>
			<Grid item xs={11} sm={11} md={6.4}>
				<Grid item xs container direction="column">
					<Grid item xs={12}>
						<Grid item xs container direction="row">
							<Grid item xs={5.5} height="100%" align="left">
								<img src={footerImage} alt="logo" className="logo-image" />
							</Grid>

							<Grid item xs={6.5} height="100%" className="login-btn-container">
								<Link to="/loginCT">
									<Button
										variant="outlined"
										color="inherit"
										className="login-btn h6"
									>
										Login
									</Button>
								</Link>
							</Grid>
						</Grid>

						<Grid item xs container direction="row">
							<Grid item xs={1} sm={1} md={2} />

							<Grid
								item
								xs={11}
								sm={11}
								md={10}
								className="payoff-one-text-container"
							>
								<Typography
									variant="h1"
									align="left"
									className="payoff-one-title"
								>
									{t('home_title')}
								</Typography>
							</Grid>
						</Grid>

						<Grid item xs container direction="row">
							<Grid item xs={1} sm={1} md={2} />
							<Grid
								item
								xs={11}
								sm={11}
								md={10}
								min-width="0"
								className="payoff-one-text-container"
							>
								<Typography
									variant="h5"
									className="payoff-one-text"
									align="justify"
									sx={{ py: '30px' }}
									paragraph
								>
									{t('heroCT_desc')}
								</Typography>
							</Grid>
						</Grid>

						<Grid item xs container direction="row">
							<Grid item xs={1} sm={1} md={2}>
								<Paper />
							</Grid>

							<Grid item xs={2}>
								<Button
									disabled={isLoading}
									variant="contained"
									className="early-user-btn h9"
									style={{ minWidth: 'unset', padding: '1% 3%' }}
									onClick={() => handleEarlyReg()}
								>
									{isLoading ? (
										<CircularProgress
											style={{ height: '20px', width: '20px' }}
											color="inherit"
										/>
									) : (
										t('hero_mail_btn')
									)}
								</Button>
							</Grid>

							<Grid item xs={9} sm={9} md={8}>
								<input
									onInput={(e) => setEmail(e.target.value)}
									value={email}
									type="email"
									placeholder="Email"
									className="email-input h7"
									name="email"
									id="email"
								/>
							</Grid>
						</Grid>

						<Grid item xs container direction="row">
							<Grid item xs={12} height="20px">
								<Paper />
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<EmailPopup
				isopen={showModal}
				onClose={() => {
					setShowModal(false)
					setEmail('')
				}}
			/>
		</Grid>
	)
}

export default NavbarHPCT
