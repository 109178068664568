import { Fragment, useEffect, useState } from 'react'

//Components
import AuthNavbar from 'components/AuthNavbar'
import LoginFooter from 'components/LoginFooter'
import PersonalData from 'components/PersonalData'
import SideBar from 'shared/Sidebar'
import LoadingIndicator from 'components/LoadingIndicator'

//Styled components for styling
import { ProfilePageContainer, LeftDiv, RightDiv } from './profilePageSkin'

//Hooks
import useAuth from 'hooks/useAuth'
import usePersonalData from '../../hooks/usePersonalData'

//APIs
import { getUserProfile } from 'api/user'

const ProfilePage = () => {
	const { auth } = useAuth()

	const [usrInfo, setUsrInfo] = useState({})
	const [currentUserInfo, setCurrentUserInfo] = useState({})
	const [isLoading, setIsLoading] = useState(true)
	const [userBirth, setUserBirth] = useState(null)
	const [userAge, setUserAge] = useState()
	const { personalData } = usePersonalData()

	useEffect(() => {
		const getUser = async () => {
			const data = await getUserProfile(auth.user.id)
			setCurrentUserInfo(data.data)
			setUsrInfo(data.data)
			setIsLoading(false)
			setUserBirth(data.data.user_birthday)
			getAge(data.data.user_birthday)
		}

		getUser()
	}, [auth.user.id])

	useEffect(() => {
		if (personalData && personalData.user_birthday) {
			getAge(personalData.user_birthday)
			setUserBirth(personalData.user_birthday)
		} else {
			setUserBirth(null)
		}
	}, [personalData])

	const getAge = (dateString) => {
		var today = new Date()
		var birthDate = new Date(dateString)
		var age = today.getFullYear() - birthDate.getFullYear()
		var m = today.getMonth() - birthDate.getMonth()
		if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
			age--
		}
		setUserAge(age)
	}

	return (
		<>
			{isLoading ? (
				<LoadingIndicator />
			) : (
				<>
					<AuthNavbar />
					<ProfilePageContainer>
						<LeftDiv>
							<SideBar userBirth={userBirth} userAge={userAge} />
						</LeftDiv>

						<RightDiv>
							<div className="contextArea">
								<div style={{ marginBottom: 0 }} className="top h4">
									Personal data
								</div>

								<div className="middle_area">
									<PersonalData
										id={auth.user.id}
										currentUserInfo={currentUserInfo}
										usrInfo={usrInfo}
										setUsrInfo={setUsrInfo}
									/>
								</div>

								<div className="bottom" />
							</div>
						</RightDiv>
					</ProfilePageContainer>

					<LoginFooter />
				</>
			)}
		</>
	)
}

export default ProfilePage
