import { Fragment } from 'react'
import AuthNavbar from 'components/AuthNavbar'
import LoginFooter from 'components/LoginFooter'
import {
	LeftDiv,
	RightDiv,
	TerminateAccountPageContainer
} from './TerminateAccountPageSkin'
import SideBar from 'shared/Sidebar'
import useAuth from 'hooks/useAuth'
import TerminateAccount from 'components/TerminateAccount'

const TerminateAccountPage = () => {
	const { auth } = useAuth()

	return (
		<Fragment>
			<AuthNavbar />
			<TerminateAccountPageContainer>
				<LeftDiv>
					<SideBar />
				</LeftDiv>

				<RightDiv>
					<div className="contextArea">
						<div style={{ marginBottom: 0 }} className="top h4">
							Account Termination
						</div>

						<div className="middle_area">
							<TerminateAccount id={auth.user.id} />
						</div>

						<div className="bottom" />
					</div>
				</RightDiv>
			</TerminateAccountPageContainer>

			<LoginFooter />
		</Fragment>
	)
}

export default TerminateAccountPage
