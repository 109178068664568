import axios from 'axios'

const URL = process.env.REACT_APP_URL

const api = axios.create({
	baseURL: `${URL}invite`
})

export const checkInvitation = async ({ token, inviteeEmail, userId }) => {
	const config = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		}
	}
	return await api.post('/check', { inviteeEmail, userId }, config)
}

export const newInvitation = async ({
	token,
	inviteeEmail,
	inviteeName,
	userId
}) => {
	const config = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		}
	}
	return await api.post('/new', { inviteeEmail, inviteeName, userId }, config)
}
