import axios from 'axios'

const URL = process.env.REACT_APP_URL

const api = axios.create({
	baseURL: `${URL}mktg`
})

export const earlyRegistration = async ({ email, action_id }) => {
	return await api.post('/early-register', { email, action_id })
}

export const saveUserEmail = async (id) => {
	const response = await api.post(`/save-email/${id}`)
	return response
}
