import styled from 'styled-components'
import { h7FontSize } from '../../appSkin.js'

export const TutorialVideoCon = styled.div`
	.react-multi-carousel-list {
	}

	.react-multi-carousel-list {
	}

	.react-multiple-carousel__arrow {
		background: transparent;
	}

	.react-multiple-carousel__arrow:hover {
		background: transparent;
	}
	.react-multiple-carousel__arrow::before {
		display: none;
	}

	.react-multiple-carousel__arrow--left {
		position: absolute !important;
		left: -0.4%;
		z-index: 1;
		border: 1px solid grey;
		border-width: 0 10px 10px 0;
		display: inline-block;
		/*  padding: 6px;*/
		opacity: 0.8;
		cursor: pointer;
		-webkit-transform: rotate(135deg) scale(0.5);
		transform: rotate(135deg) scale(0.5);
		border-radius: 0px;
	}

	.react-multiple-carousel__arrow--right {
		position: absolute !important;
		right: -0.4%;
		z-index: 1;
		border: 1px solid grey;
		border-width: 0 10px 10px 0;
		display: inline-block;
		/*padding: 6px;*/
		opacity: 0.8;
		cursor: pointer;
		-webkit-transform: rotate(-45deg) scale(0.5);
		transform: rotate(-45deg) scale(0.5);
		border-radius: 0px;
	}

	@media screen and (max-width: 800px) {
		.react-multiple-carousel__arrow--right {
			-webkit-transform: rotate(-45deg) scale(0.5);
			transform: rotate(-45deg) scale(0.4);
		}

		.react-multiple-carousel__arrow--left {
			-webkit-transform: rotate(135deg) scale(0.5);
			transform: rotate(135deg) scale(0.4);
		}
	}

	@media screen and (max-width: 600px) {
		.react-multiple-carousel__arrow--right {
			-webkit-transform: rotate(-45deg) scale(0.5);
			transform: rotate(-45deg) scale(0.3);
		}

		.react-multiple-carousel__arrow--left {
			-webkit-transform: rotate(135deg) scale(0.5);
			transform: rotate(135deg) scale(0.3);
		}
	}

	.videos-item {
		width: 86%;
		border: 1px dashed gray;
		height: auto;
		margin-left: 7%;
		margin-top: 7%;
		margin-bottom: 7%;
		// padding: 1.5% 0.8% 1.5% 0.8%;
	}
	.videos-image {
		border: 0px;
		width: 100%;
		height: auto;
		display: flex;
		position: relative;
		justify-content: center;
		margin: auto;
	}

	.videos-name {
		text-align: left;
		padding-left: 2%;
		padding-bottom: 5%;
		margin-top: 10%;
		margin-bottom: 5%;
		${h7FontSize};
		font-weight: bold;
		line-height: 100%;
	}
	.tutorial-videos-text {
		text-align: left;
		padding-top: 5px;
		padding-left: 2%;
		padding-bottom: 10%;
	}
	.start-button {
		background-color: #1cc743;
		width: 80%;
		margin-left: 15%;
		margin-right: 0%;
		padding-left: 0%;
		padding-right: 0%;
	}
	.start-button:hover {
		background-color: #1cc743;
	}
	.containerSize {
		width: '325.66px';
		height: '471.73px';
	}
`
