import styled from '@emotion/styled/macro'
import {
	h3_1FontSize,
	h3_2FontSize,
	h3_3FontSize,
	h7FontSize
} from '../../appSkin.js'

export const HPearlyRegContainer = styled.div`
	width: 100%;
	height: auto;
	background-image: url(${(props) =>
		props.background ? props.background : 'none'});
	background-repeat: no-repeat;
	background-size: cover;

	.payoff-one-image {
		width: 100%;
		margin-bottom: -21%;
	}
	.logo-image {
		width: 100%;
		max-width: 150px;
		height: auto;
		margin-left: 5%;
		margin-top: 1.5%;
	}
	.login-btn-container {
		padding-right: 5%;
	}
	.login-btn {
		margin-top: 1.5%;
		position: absolute;
		color: white;
		border-color: white;
		right: 2%;
		width: 10%;
	}
	button[disabled] {
		background-color: #1cc743 !important;
		color: #fff !important;
	}
	.payoff-one-text-container {
		color: white;
	}
	.payoff-one-title {
		font-weight: bold;
		${h3_3FontSize}
	}
	.payoff-one-text {
		margin-bottom: 2%;
		padding-top: 4%;
		padding-bottom: 4%;
		${h7FontSize}
	}
	.early-user-btn {
		width: 100%;
		height: 97%;
		border-radius: 5px;
		background-color: #1cc743;
	}
	.early-user-btn:hover {
		background-color: #1cc743;
	}
	.email-input {
		width: 95%;
		height: 100%;
		border-radius: 5px;
		margin-bottom: 2%;
		margin-left: 2%;
		background-color: white;
	}

	@media (max-width: 600px) {
		.css-1o7apob-MuiGrid-root {
			max-width: 22.666667%;
			display: block;
		}
		.ciwiZG .css-158u7se-MuiGrid-root {
			max-width: 65%;
			margin-left: 10px;
		}
		.euqSmm.h9.jdGixe.early-user-btn {
			width: 126%;
		}
		.ciwiZG .css-sghohy-MuiButtonBase-root-MuiButton-root {
			min-width: 0 !important;
		}
		.css-158u7se-MuiGrid-root {
			flex-basis: 70%;
			flex-grow: 0;
			max-width: 75%;
		}
	}

	@media (max-width: 400px) {
		.css-sghohy-MuiButtonBase-root-MuiButton-root {
			min-width: unset;
		}
	}
	.css-sghohy-MuiButtonBase-root-MuiButton-root {
		padding: 1% 3% important;
	}
`
