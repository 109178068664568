import { Fragment, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import {
	CircularProgress,
	Dialog,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton
} from '@mui/material'

import Button from '@mui/material/Button'
import toast from 'react-hot-toast'

import backgroundImage from '../../images/Path 845.png'
import { CheckoutModalSkin } from './checkouModalSkins'
import { createPayment } from '../../api/payment'
import useAuth from '../../hooks/useAuth'

const CheckoutModal = ({ open, setOpen, user, selectedPlan }) => {
	const { auth } = useAuth()

	const [selected, setSelected] = useState()
	const [loading, setLoading] = useState(false)

	const week =
		selectedPlan.plan_units * user.user_subMktg_id.subMktg_unit_id.unit_weeks

	const price = (
		(
			user.user_subMktg_id.subMktg_unit_id.unit_price -
			(selectedPlan.plan_discount / 100) *
				user.user_subMktg_id.subMktg_unit_id.unit_price
		).toFixed(2) * selectedPlan.plan_units
	).toFixed(2)

	const currency = user.user_subMktg_id.subMktg_unit_id.unit_currency

	const handlePaymentChecked = async () => {
		if (selected) {
			setLoading(true)
			const result = await createPayment({
				token: auth.token,
				body: {
					user: user._id,
					selectedPlan: selectedPlan._id,
					week,
					price,
					currency,
					method: selected
				}
			})
			setLoading(false)

			if (result.error) {
				if (result.error.response) {
					toast.error(result.error.response.data.message)
				} else if (result.error.request) {
					toast.error(result.error.request)
				} else {
					toast.error(result.error.message)
				}
			} else {
				//Console added to check warnings thrown by Stripe's page - To Be Removed for production
				console.log('result data url ', result)

				window.location = result.data.url
			}
		} else {
			toast.error('Select A Payment Method First')
		}
	}

	const modalClose = () => {
		setOpen(!open)
	}

	return (
		<Dialog
			open={open}
			onClose={modalClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<CheckoutModalSkin background={backgroundImage}>
				<Fragment>
					<DialogTitle
						id="alert-dialog-title"
						className="alert-dialog-title"
						sx={{ display: 'flex', justifyContent: 'space-between' }}
					>
						<div
							style={{ textAlign: 'center', flex: 1 }}
							className="dialog-title-text"
						>
							Subscription Confirmation
						</div>
						<IconButton onClick={modalClose}>
							<CloseIcon sx={{ color: 'white' }} />
						</IconButton>
					</DialogTitle>
					<DialogContent>
						<DialogContentText
							style={{ marginBottom: '10px' }}
							className="alert-dialog-description"
						>
							You subscribed a {week} weeks plan for the total cost of {price}{' '}
							{currency}.
						</DialogContentText>
						<DialogContentText
							style={{ marginBottom: '10px' }}
							className="alert-dialog-description"
						>
							The plan will start immediately, the residual time of the previous
							plan will be added to the new one extending its duration.
						</DialogContentText>
						<DialogContentText
							style={{ marginBottom: '10px' }}
							className="alert-dialog-description"
						>
							Please choose the payment method:
						</DialogContentText>

						<div className="paymentOption">
							<div className="methods">
								<div>
									<DialogContentText className="alert-dialog-description content-flex">
										Paypal
									</DialogContentText>
									<div>
										<input
											onChange={(e) => setSelected(e.target.value)}
											type="radio"
											id="paypal"
											name="pay"
											value="paypal"
										/>
										<label htmlFor="paypal" className="input-container" />
									</div>
								</div>
								<div>
									<DialogContentText className="alert-dialog-description content-flex">
										Credit Card
									</DialogContentText>

									<div>
										<input
											onChange={(e) => setSelected(e.target.value)}
											type="radio"
											id="visa"
											name="pay"
											value="visa"
										/>
										<label htmlFor="visa" className="input-container" />
									</div>
								</div>
							</div>
							<div className="proced">
								<Button
									disabled={loading}
									variant="outlined"
									className="subs_btn"
									onClick={handlePaymentChecked}
								>
									{loading ? (
										<CircularProgress
											style={{ height: '28px', width: '28px' }}
											color="inherit"
										/>
									) : (
										'Proceed'
									)}
								</Button>
							</div>
						</div>
					</DialogContent>
				</Fragment>
			</CheckoutModalSkin>
		</Dialog>
	)
}

export default CheckoutModal
