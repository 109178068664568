import { Dialog, styled } from '@mui/material'
import { DialogueBox } from './newAlertBoxSkin'
import CloseIcon from '@mui/icons-material/Close'

const BootstrapDialog = styled(Dialog)(() => ({
	'& .MuiPaper-root': {
		width: '30%'
	}
}))

const Alert = ({ isModalOpen, title, description, setIsModalOpen }) => {
	return (
		<BootstrapDialog
			aria-labelledby="customized-dialog-title"
			open={isModalOpen}
		>
			<DialogueBox>
				<div className="header">
					<p className="header-text">{title}</p>
					<CloseIcon
						className="cross-icon"
						onClick={() => setIsModalOpen(false)}
					/>
				</div>

				<div className="description">
					<p>{description}</p>
				</div>
			</DialogueBox>
		</BootstrapDialog>
	)
}

export default Alert
