import axios from 'axios'

const URL = process.env.REACT_APP_URL

const api = axios.create({
	baseURL: `${URL}consent`
})

export const retrieveConsent = async (id) => {
	try {
		const result = await api.get(`/retrieve/?userId=${id} `)

		//********************************************* */
		console.log('retrieveConsent launched!')
		// console.log('userId = ', id)
		// console.log('result = ', result)
		//********************************************* */

		return result.data
	} catch (error) {
		// Handle errors here. You could throw the error or return a default value.
		console.error({
			function: 'retrieveConsent',
			message: error.response || error.message
		})
		throw error // Re-throw the error to be handled by the caller.
	}
}
