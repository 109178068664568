import styled from '@emotion/styled'
import { h7FontSize, h9FontSize } from '../../appSkin.js'

export const TerminateSkin = styled.div`
	font-family: Segoe UI;

	.h2 {
		color: white;
	}

	.h7 {
		color: white;
		${h7FontSize};

		p + p {
			display: block;
			margin-top: 1em; // Adds a top margin to the <p> element for spacing.
		}
	}

	.h9 {
		color: white;
		${h9FontSize};
	}

	.text-center {
		text-align: center;
	}

	.header-section {
		background-color: #a6b1ff;
		color: #000;
		font-weight: bold;
		border-radius: 15px 15px 0 0;
		height: 70px;
		display: flex;
		border: 1px solid #000;
		justify-content: space-between;
		flex-direction: column;

		.header-top {
			height: 35px;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.header-bottom {
			height: 35px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	.invite_title {
		color: white;
		margin-top: 70px;
	}
	.inviteBtn {
		width: 350px;
		height: 90px;
		background: #6a7ae9 !important;
		border: 2px solid #55657e !important;
		border-radius: 10px !important;
		text-transform: capitalize !important;
	}
	.referral_form {
		width: 100%;
		margin-top: 50px !important;
	}
	.ref_footer {
		color: white;
	}
	.notifying {
		color: #e9bb6a !important;
		text-align: center !important;
		margin-top: 41px;
	}

	.subsHeadBottom {
		display: flex;
		border-top: 3px solid black;
		box-sizing: border-box;
	}
	.subsHeadBottom span {
		flex-grow: 1;
	}

	.subsTable thead th {
		padding: 0 !important;
	}

	.left-Align {
		text-align: left;
	}
`
