import { useState, useEffect, Fragment } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import { Button, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Swal from 'sweetalert2'
import toast from 'react-hot-toast'
import usePersonalData from '../../hooks/usePersonalData'

import { updateProfile } from '../../api/user'

const PersonalData = ({ id, usrInfo, setUsrInfo, currentUserInfo }) => {
	const { t } = useTranslation()

	const {
		user_id,
		user_email,
		user_name,
		user_nation,
		user_edu,
		user_town,
		user_job,
		user_address,
		user_sex,
		user_surname,
		user_birthday,
		user_mainUse,
		user_publicData
	} = usrInfo

	const [isEdit, setEdit] = useState(false)
	const [isReadOnly, setReadOnly] = useState('none')

	const [education, setEducation] = useState([])
	const [jobs, setJobs] = useState([])
	const [uses, setUses] = useState([])
	const { setPersonalData } = usePersonalData()

	const saveInfo = async () => {
		if (
			usrInfo.user_email !== currentUserInfo.user_email ||
			usrInfo.user_id !== currentUserInfo.user_id
		) {
			Swal.fire({
				position: 'center',
				icon: 'error',
				title: 'Something Wrong!',
				text: "Email or password isn't changeable right now..",
				showConfirmButton: false,
				timer: 2500
			})
		} else {
			try {
				setPersonalData({
					usrInfo: user_publicData,
					user_birthday: user_birthday
				})
				const { data } = await updateProfile(id, usrInfo)
				if (data.data === 'success') {
					Swal.fire({
						position: 'center',
						icon: 'success',
						title: 'Saved!',
						html: 'Your file has been saved.',
						showConfirmButton: false,
						timer: 1500
					})
				}
			} catch (error) {
				if (error.response) {
					toast(error.response.data.message)
				} else if (error.request) {
					toast(error.request)
				} else {
					toast(error.message)
				}
			}
		}
	}

	useEffect(() => {
		fetch('/education.csv')
			.then((response) => response.text())
			.then((responseText) => {
				setEducation(responseText.split(';'))
			})
	}, [])

	useEffect(() => {
		fetch('/jobs.csv')
			.then((response) => response.text())
			.then((responseText) => {
				setJobs(responseText.split(';'))
			})
	}, [])

	useEffect(() => {
		fetch('/use.csv')
			.then((response) => response.text())
			.then((responseText) => {
				setUses(responseText.split(';'))
			})
	}, [])

	function checkHandler(e) {
		const newData = { ...usrInfo }
		const newChecked = { ...newData.user_publicData }
		newChecked[e.target.name] = e.target.checked
		newData.user_publicData = newChecked
		setUsrInfo(newData)
	}

	const valueHandler = (e) => {
		const newData = { ...usrInfo }
		newData[e.target.name] = e.target.value
		setUsrInfo(newData)
	}

	const dateValidationHandler = (event) => {
		const selectedDate = new Date(event.target.value)
		const currentDate = new Date()
		const minAllowedBirthDate = new Date(
			currentDate.getFullYear() - 13,
			currentDate.getMonth(),
			currentDate.getDate()
		)

		if (selectedDate > minAllowedBirthDate) {
			alert(t('personalData_ageWarning'))
			return
		}

		valueHandler(event)
	}

	return (
		<Grid container="fluid">
			<div className="personalData">
				<p className="message h7">{t('personal_data_title')}</p>

				<div className="private_data">
					<div className="left actionButtons">
						{isEdit || (
							<Button
								onClick={() => {
									setReadOnly('inherit')
									setEdit(true)
								}}
								className="h4"
								variant="contained"
							>
								<EditIcon />
							</Button>
						)}

						{isEdit && (
							<Fragment>
								<Button
									onClick={() => {
										Swal.fire({
											title: 'Are you sure?',
											icon: 'warning',
											showCancelButton: true,
											confirmButtonColor: '#3085d6',
											cancelButtonColor: '#d33',
											confirmButtonText: 'Yes, save it!'
										}).then((result) => {
											if (result.isConfirmed) {
												setReadOnly('none')
												setEdit(false)
												saveInfo()
											}
										})
									}}
									className="h4"
									variant="contained"
								>
									Save
								</Button>
								<Button
									onClick={() => {
										setReadOnly('none')
										setEdit(false)
										setUsrInfo({ ...currentUserInfo })
									}}
									className="h4"
									variant="contained"
								>
									Cancel
								</Button>
							</Fragment>
						)}
					</div>

					<div className="middle" />
					<div className="right" />
				</div>

				<div className="private_data">
					<div className="left">
						<div className="inputfiled-container">
							<div className="labels h6">Name</div>
							<input
								value={user_name}
								name="user_name"
								id="user_name"
								onChange={valueHandler}
								className="h7"
								style={{ width: '100%' }}
								readOnly={!isEdit}
								type="text"
							/>
						</div>
						<div className="inputfiled-container">
							<div className="labels h6">Nation of residence</div>
							<input
								value={user_nation}
								name="user_nation"
								id="user_nation"
								onChange={valueHandler}
								className="h7"
								style={{ width: '100%' }}
								readOnly={!isEdit}
								type="text"
							/>
						</div>

						<div className="inputfiled-container">
							<div className="labels h6">Educational background</div>
							{isEdit ? (
								<select
									defaultValue={user_edu}
									onChange={valueHandler}
									name="user_edu"
									style={{ width: '100%' }}
									className="h7 educationalBg"
								>
									<option value="" hidden>
										--Choose your edu--
									</option>
									{education.map((data) => (
										<option key={data} value={data}>
											{data}
										</option>
									))}
								</select>
							) : (
								<input
									value={user_edu}
									name="user_edu"
									id="user_edu"
									className="h7"
									style={{ width: '100%', textTransform: 'capitalize' }}
									readOnly={!isEdit}
									type="text"
								/>
							)}
						</div>
					</div>
					<div className="middle">
						<div className="inputfiled-container">
							<div className="labels h6">Surname</div>
							<input
								className="h7"
								value={user_surname}
								name="user_surname"
								id="user_surname"
								onChange={valueHandler}
								style={{ width: '100%' }}
								readOnly={!isEdit}
								type="text"
							/>
						</div>
						<div className="inputfiled-container">
							<div className="labels h6">Town of residence</div>
							<input
								value={user_town}
								name="user_town"
								id="user_town"
								onChange={valueHandler}
								className="h7"
								style={{ width: '100%' }}
								readOnly={!isEdit}
								type="text"
							/>
						</div>

						<div className="inputfiled-container">
							<div className="labels h6">Job</div>
							{isEdit ? (
								<select
									defaultValue={user_job}
									onChange={valueHandler}
									name="user_job"
									style={{ width: '100%' }}
									className="h7 educationalBg"
								>
									<option value="" hidden>
										--Choose your job--
									</option>
									{jobs.map((data) => (
										<option
											key={data}
											style={{ textTransform: 'capitalize' }}
											value={data}
										>
											{data}
										</option>
									))}
								</select>
							) : (
								<input
									value={user_job}
									className="h7"
									style={{ width: '100%', textTransform: 'capitalize' }}
									readOnly={!isEdit}
									id="user-job"
									name="user-job"
									type="text"
								/>
							)}
						</div>
					</div>
					<div className="right">
						<div className="inputfiled-container">
							<div className="labels h6">Sex</div>
							{isEdit ? (
								<select
									defaultValue={user_sex}
									onChange={valueHandler}
									name="user_sex"
									style={{ width: '100%' }}
									className="h7"
								>
									<option value="" hidden>
										--Choose your sex--
									</option>
									<option value="female">Female</option>
									<option value="male">Male</option>
								</select>
							) : (
								<input
									value={user_sex}
									className="h7"
									style={{ width: '100%', textTransform: 'capitalize' }}
									readOnly={!isEdit}
									type="text"
									id="gender"
									name="gender"
								/>
							)}
						</div>
						<div className="inputfiled-container">
							<div className="labels h6">Birth Date</div>
							<input
								className="h7"
								style={{ width: '100%' }}
								readOnly={!isEdit}
								type={user_birthday || isEdit ? 'date' : 'text'}
								value={user_birthday?.slice(0, 10)}
								onChange={dateValidationHandler}
								name="user_birthday"
								id="user_birthday"
							/>
						</div>

						<div className="inputfiled-container">
							<div className="labels h6">Main use</div>
							{isEdit ? (
								<select
									defaultValue={user_mainUse}
									onChange={valueHandler}
									name="user_mainUse"
									style={{ width: '100%' }}
									className="h7"
								>
									<option value="" hidden>
										--Choose use--
									</option>
									{uses.map((u) => (
										<option key={u} value={u}>
											{u}
										</option>
									))}
								</select>
							) : (
								<input
									value={user_mainUse}
									className="h7"
									style={{ width: '100%', textTransform: 'capitalize' }}
									readOnly={!isEdit}
									type="text"
									name="user_main_use"
									id="user_main_use"
								/>
							)}
						</div>
					</div>
				</div>
			</div>

			<div className="personalData">
				<p className="message h7">
					Select which of the above information should be visible on your public
					profile
				</p>

				<div className="private_data visible_info ">
					<div className="left">
						<div className="check">
							<div className="form-group">
								<input
									checked={user_publicData.user_name}
									type="checkbox"
									onChange={checkHandler}
									id="Name"
									name="user_name"
								/>

								<label
									style={{
										pointerEvents: isReadOnly,
										display: 'flex',
										alignItems: 'center'
									}}
									className="h6"
									htmlFor="Name"
								>
									Name
								</label>
							</div>
						</div>
						<div className="check">
							<div className="form-group">
								<input
									checked={user_publicData?.user_nation}
									type="checkbox"
									onChange={checkHandler}
									id="residence"
									name="user_nation"
								/>

								<label
									style={{
										pointerEvents: isReadOnly,
										display: 'flex',
										alignItems: 'center'
									}}
									className="h6"
									htmlFor="residence"
								>
									Nation of residence
								</label>
							</div>
						</div>
						<div className="check">
							<div className="form-group">
								<input
									checked={user_publicData.user_edu}
									type="checkbox"
									onChange={checkHandler}
									id="edb"
									name="user_edu"
								/>
								<label
									style={{
										pointerEvents: isReadOnly,
										display: 'flex',
										alignItems: 'center'
									}}
									className="h6"
									htmlFor="edb"
								>
									Educational background
								</label>
							</div>
						</div>
					</div>
					<div className="middle">
						<div className="check">
							<div className="form-group">
								<input
									checked={user_publicData.user_surname}
									type="checkbox"
									onChange={checkHandler}
									id="Surname"
									name="user_surname"
								/>
								<label
									style={{
										pointerEvents: isReadOnly,
										display: 'flex',
										alignItems: 'center'
									}}
									className="h6"
									htmlFor="Surname"
								>
									Surname
								</label>
							</div>
						</div>

						<div className="check">
							<div className="form-group">
								<input
									checked={user_publicData.user_town}
									type="checkbox"
									onChange={checkHandler}
									id="Town"
									name="user_town"
								/>

								<label
									style={{
										pointerEvents: isReadOnly,
										display: 'flex',
										alignItems: 'center'
									}}
									className="h6"
									htmlFor="Town"
								>
									Town of residence
								</label>
							</div>
						</div>

						<div className="check">
							<div className="form-group">
								<input
									checked={user_publicData.user_job}
									type="checkbox"
									onChange={checkHandler}
									id="Job"
									name="user_job"
								/>
								<label
									style={{
										pointerEvents: isReadOnly,
										display: 'flex',
										alignItems: 'center'
									}}
									className="h6"
									htmlFor="Job"
								>
									Job
								</label>
							</div>
						</div>
					</div>

					<div className="right">
						<div className="check">
							<div className="form-group">
								<input
									checked={user_publicData.user_sex}
									type="checkbox"
									onChange={checkHandler}
									id="sex"
									name="user_sex"
								/>
								<label
									style={{
										pointerEvents: isReadOnly,
										display: 'flex',
										alignItems: 'center'
									}}
									className="h6"
									htmlFor="sex"
								>
									Sex
								</label>
							</div>
						</div>
						<div className="check">
							<div className="form-group">
								<input
									checked={user_publicData.user_birthday}
									type="checkbox"
									onChange={checkHandler}
									id="dob"
									name="user_birthday"
								/>
								<label
									style={{
										pointerEvents: isReadOnly,
										display: 'flex',
										alignItems: 'center'
									}}
									className="h6"
									htmlFor="dob"
								>
									Birth date
								</label>
							</div>
						</div>
						<div className="check">
							<div className="form-group">
								<input
									checked={user_publicData.user_mainUse}
									type="checkbox"
									onChange={checkHandler}
									id="mainuse"
									name="user_mainUse"
								/>
								<label
									style={{
										pointerEvents: isReadOnly,
										display: 'flex',
										alignItems: 'center'
									}}
									className="h6"
									htmlFor="mainuse"
								>
									Main use
								</label>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div style={{ marginBottom: 0 }} className="personalData">
				<p className="message h7">
					The following is strictly private information:
				</p>

				<Grid container className="private_field" columnSpacing={4}>
					<Grid item xs={9}>
						<div className="inputfiled-container">
							<div className="labels h6">Address</div>
							<input
								value={user_address}
								name="user_address"
								id="user_address"
								onChange={valueHandler}
								className="h7"
								style={{ width: '100%' }}
								readOnly={!isEdit}
								type="text"
							/>
						</div>
					</Grid>

					<Grid item xs={3} />

					<Grid item xs={4.5}>
						<div className="inputfiled-container">
							<div className="labels h6">
								Email <span className="labels h8">(Not editable)</span>
							</div>
							<input
								value={user_email}
								name="user_email"
								id="user_email"
								onChange={valueHandler}
								className="h7"
								style={{ width: '100%' }}
								// readOnly={!isEdit}
								readOnly={true}
								type="email"
							/>
						</div>
					</Grid>
					<Grid item xs={4.5}>
						<div className="inputfiled-container">
							<div className="labels h6">
								UserId <span className="labels h8">(Not editable)</span>
							</div>
							<input
								value={user_id}
								name="user_Id"
								id="user_Id"
								onChange={valueHandler}
								className="h7"
								style={{ width: '100%' }}
								// readOnly={!isEdit}
								readOnly={true}
								type="text"
							/>
						</div>
					</Grid>

					<Grid item xs={4} />
				</Grid>
			</div>
		</Grid>
	)
}

export default PersonalData
